import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import { Card, Row } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
} from "../../components/Component";
import Nouislider from "nouislider-react";
import {
  getPayoutList,
  payoutInfo,
  savePayoutDetail,
  addWire,
  getSelectedPayoutList,
} from "../../app/api";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../app/Loader";

const UserPayment = ({ sm, updateSm }) => {
  const [loading, setLoading] = useState(false);
  const [lim, setLim] = useState(100);
  const [payMode, setPayMode] = useState();
  const [payAccId, setPayAccId] = useState("");
  const [payModeID, setPayModeID] = useState(0);
  const [wamt, setWamt] = useState(lim);
  const [pdata, setPdata] = useState(null);

  const [file, setFile] = useState(null); // added by arvind
  const [file1, setFileUpdate] = useState(null); // added by arvind

  // const imageUrl = file1 ? `http://localhost:8000/qr_images/${file1}` : '';  //image url for localhost server
  const imageUrl = file1
    ? `https://services.7searchppc.in/qr_images/${file1}`
    : ""; //image url for dot in server
  // const imageUrl = file1 ? `https://services.7searchppc.com/qr_images/${file1}` : '';  //image url for dot com server

  const fileURL = file ? URL.createObjectURL(file) : imageUrl;

  const [bankDetails, setBankDetails] = useState({
    bank_name: "",
    account_holder_name: "",
    account_number: "",
    ifsc_code: "",
    swift_code: "",
    iban_code: "",
  });
  const [errors, setErrors] = useState({});

  //validation added by arvind
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxSize = 1 * 1024 * 1024; // 1 MB in bytes

    if (selectedFile) {
      // Check file type and size
      if (!validTypes.includes(selectedFile.type)) {
        toast.error("Invalid file type. Only jpg, jpeg, and png are allowed.");
        e.target.value = "";
        return false;
      }

      if (selectedFile.size > maxSize) {
        toast.error("File size should be less than 1 MB.");
        e.target.value = "";
        return false;
      }

      // If valid, set the file
      if (validateFile(selectedFile)) {
        setFile(selectedFile);
      }
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setFileUpdate(null);
    document.querySelector('input[type="file"]').value = "";
  };

  const validateFile = (file) => {
    // Add your file validation logic here (e.g., type, size)
    return true;
  };

  const savePayData = async () => {
    let uid = localStorage.getItem("uid");

    if (payModeID > 0) {
      if (payMode === "Wire Transfer") {
        const newErrors = {};
        if (!bankDetails.bank_name || /^\s*$/.test(bankDetails.bank_name)) {
          newErrors.bank_name = "Bank name is required";
        } else if (!/^[a-zA-Z\s]+$/.test(bankDetails.bank_name)) {
          newErrors.bank_name = "Bank name must be alphabetical";
        }
        if (
          !bankDetails.account_holder_name ||
          /^\s*$/.test(bankDetails.account_holder_name)
        ) {
          newErrors.account_holder_name = "Account holder name is required";
        } else if (!/^[a-zA-Z\s]+$/.test(bankDetails.account_holder_name)) {
          newErrors.account_holder_name =
            "Account holder name must be alphabetical";
        }
        if (
          !bankDetails.account_number ||
          /^\s*$/.test(bankDetails.account_number)
        ) {
          newErrors.account_number = "Account number is required";
        } else if (!/^\d+$/.test(bankDetails.account_number)) {
          newErrors.account_number = "Account numbers must be numeric";
        } else {
          if (bankDetails.account_number.length < 8) {
            newErrors.account_number =
              "The account number must have at least 8 digits";
          } else if (bankDetails.account_number.length > 35) {
            newErrors.account_number =
              "The account number must have maximum 35 digits";
          }
        }
        if (!bankDetails.ifsc_code || /^\s*$/.test(bankDetails.ifsc_code)) {
          newErrors.ifsc_code = "IFSC code is required";
        }

        // If there are errors, set them and stop submission
        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          return;
        }

        const copiedObject = { ...bankDetails };
        copiedObject.publisher_id = uid;
        copiedObject.payout_id = payModeID;
        copiedObject.payout_name = payMode;
        copiedObject.pub_withdrawl_limit = wamt;
        copiedObject.minimum_amount = lim;

        // console.log("Bank Details", copiedObject);
        setLoading(true);
        let res = await addWire(copiedObject);
        if (res.code === 200) {
          toast.success("Payment Setting Updated!", {
            position: "top-right",
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
          setErrors({});
        } else if (res.code === 422) {
          toast.error(res.message, {
            position: "top-right",
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        }
      } else {
        if (payAccId) {
          // Create a FormData object
          let formData = new FormData();

          // Append the other data
          formData.append("publisher_id", uid);
          formData.append("payout_id", payModeID);
          formData.append("payout_name", payMode);
          formData.append("pub_withdrawl_limit", wamt);
          formData.append("pay_account_id", payAccId);

          // Include file only if it exists
          if (file && payMode.toLowerCase() == "upi") {
            formData.append("qr_image", file); // Append the file directly
          }
          if (file1 && payMode.toLowerCase() == "upi") {
            formData.append("qr_image", file1);
          }

          let res = await savePayoutDetail(formData);
          // console.log('else: ', res);
          if (res.code === 200) {
            toast.success("Payment Setting Updated!", {
              position: "top-right",
              autoClose: true,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            });
            setErrors({});
          } else if (res.code === 422) {
            toast.error(res.message, {
              position: "top-right",
              autoClose: true,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            });
          }
        } else {
          toast.error("Please enter " + payMode + " Account ID", {
            position: "top-right",
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        }
      }
    } else {
      toast.error("Please select a payment option.", {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });
    }
    setLoading(false);
  };

  //validation added by arvind

  const getPayInfo = async () => {
    setLoading(true);
    let res = await payoutInfo();
    setPdata(res.data);

    // console.log(res.data)
    // setLim(res.data.withdrawl_limit)
    // setWamt(res.wid_limit)
    setLoading(false);
  };

  const [poutData, setPoutData] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBankDetails({ ...bankDetails, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const getSelectedPayMethod = async (payModId) => {
    let uid = localStorage.getItem("uid");
    let res = await getSelectedPayoutList({
      uid: uid,
      payout_id: payModId,
    });
    if (res.code == 101) {
      setWamt(lim);
      setPayAccId("");
    } else if (res.data.qr_image !== null) {
      setFileUpdate(res.data.qr_image);
      // setFile(res.data.qr_image);
      setPayAccId(res.data.pay_account_id);
      setWamt(res.data.pub_withdrawl_limit);
    } else if (res.data.pay_account_id !== null) {
      setPayAccId(res.data.pay_account_id);
      setWamt(res.data.pub_withdrawl_limit);
    } else if (res.data.bank_name !== null) {
      setWamt(res.data.pub_withdrawl_limit);
    }
    return res.code;
  };

  const getPayList = async () => {
    setLoading(true);
    let uid = localStorage.getItem("uid");
    let res = await getPayoutList({
      uid: uid,
    });

    if (res) {
      res.data.map((item) => {
        if (item.user_opt == 1) {
          setLim(item.min_withdrawl);
          setWamt(res.wid_limit);
          setFileUpdate(res.qr_image);
          setPayMode(res.payout_name);
          setPayAccId(res.pay_account_id);
          setPayModeID(item.id);
        }
      });
      setBankDetails({
        bank_name: res.bank_name,
        account_holder_name: res.account_holder_name,
        account_number: res.account_number,
        ifsc_code: res.ifsc_code,
        swift_code: res.swift_code,
        iban_code: res.iban_code,
      });

      setPoutData(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPayInfo();
    getPayList();
  }, []);

  return (
    <React.Fragment>
      <Loader visible={loading} />
      <Head title="Payment Methods"></Head>

      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Manage Payment</BlockTitle>
            <BlockDes>
              {/* <p>Here you can set your payment method and Withdrawal limit</p> */}
              <p>
                Payment dates are the{" "}
                <b>
                  1<sup>st</sup>
                </b>{" "}
                and{" "}
                <b>
                  15<sup>th</sup>
                </b>{" "}
                of each month. If these dates fall on{" "}
                <b>weekends or holidays</b> the payment will be processed on
                nearest business day. Once you reach the minimum payout amount,
                the money will be put on a two week hold. You will get the
                payout on the nearest payment date after the hold period ends.
              </p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${
                sm ? "active" : ""
              }`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <div className="row">
          {poutData &&
            poutData.map((item, key) => {
              return (
                <Card className="card-bordered paycard m-4" key={key}>
                  <div className="card-inner-group">
                    <div className="card-inner">
                      <div className="between-center flex-wrap flex-md-nowrap g-3">
                        <div className="nk-block-text">
                          <h6>
                            {/* <Icon name="sign-paypal-alt" style={{fontSize:18}}/> */}
                            <img
                              src={item.image}
                              style={{ height: "27px", width: "27px" }}
                            />{" "}
                            &nbsp; {item.display_name}
                          </h6>
                          <p>
                            Minimum Amount : <b>${item.min_withdrawl} </b>{" "}
                            &nbsp; &nbsp; Fee : ${item.processing_fee}
                          </p>
                        </div>
                        <div className="nk-block-actions">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id={`payopt` + key}
                              name="payopt"
                              className="custom-control-input form-control"
                              defaultChecked={item.user_opt == 1 ? true : false}
                              onClick={() => {
                                setPayModeID(item.id);
                                setPayMode(item.method_name);
                                setLim(item.min_withdrawl);
                                let res = getSelectedPayMethod(item.id);
                                res
                                  .then(function (result) {
                                    if (result == 101) {
                                      setWamt(item.min_withdrawl);
                                    }
                                  })
                                  .catch(function (error) {
                                    console.error("Error occurred:", error);
                                  });
                              }}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`payopt` + key}
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              );
            })}
        </div>
        <Card className="card-bordered paycard mb-4">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="between-center flex-wrap flex-md-nowrap g-3">
                <div
                  className={
                    payMode == "Wire Transfer"
                      ? `nk-block-text w-100`
                      : `nk-block-text`
                  }
                >
                  {/* <h6>{payMode} Account {(payMode == "Wire Transfer")?`Details`:`ID`}</h6>
                  <p>Update your {payMode} account {(payMode == "Wire Transfer")?`details`:`id`}.</p> */}
                  <h6>
                    {payMode + " "}
                    {payMode == "PayPal"
                      ? "Email Address"
                      : payMode == "Wire Transfer"
                      ? "Account Details"
                      : payMode == "UPI"
                      ? "ID"
                      : "Wallet Address"}
                  </h6>
                  <p>
                    Update your {payMode + " "}
                    {payMode == "PayPal"
                      ? "Email Address"
                      : payMode == "Wire Transfer"
                      ? "Account Details"
                      : payMode == "UPI"
                      ? "ID"
                      : "Wallet Address"}
                    .
                  </p>

                  {payMode == "Wire Transfer" ? (
                    <div className="row">
                      <div className="col-md-6 my-1">
                        <div className="form-group">
                          <label className="form-label">
                            Bank Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="bank_name"
                            value={bankDetails.bank_name}
                            placeholder="Enter Your Bank Name"
                            onChange={handleInputChange}
                          />
                          {errors.bank_name && (
                            <span className="text-danger">
                              {errors.bank_name}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 my-1">
                        <div className="form-group">
                          <label className="form-label">
                            Account Holder Name{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="account_holder_name"
                            value={bankDetails.account_holder_name}
                            placeholder="Enter Account Holder Name"
                            onChange={handleInputChange}
                          />
                          {errors.account_holder_name && (
                            <span className="text-danger">
                              {errors.account_holder_name}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 my-1">
                        <div className="form-group">
                          <label className="form-label">
                            Account Number{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="account_number"
                            value={bankDetails.account_number}
                            placeholder="Enter Account Number"
                            onChange={handleInputChange}
                          />
                          {errors.account_number && (
                            <span className="text-danger">
                              {errors.account_number}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 my-1">
                        <div className="form-group">
                          <label className="form-label">
                            IFSC Code <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="ifsc_code"
                            value={bankDetails.ifsc_code}
                            placeholder="Enter IFSC Code"
                            onChange={handleInputChange}
                          />
                          {errors.ifsc_code && (
                            <span className="text-danger">
                              {errors.ifsc_code}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 my-1">
                        <div className="form-group">
                          <label className="form-label">SWIFT Code </label>
                          <input
                            type="text"
                            className="form-control"
                            name="swift_code"
                            value={bankDetails.swift_code}
                            placeholder="Enter SWIFT Code"
                            onChange={handleInputChange}
                          />
                          {errors.swift_code && (
                            <span className="text-danger">
                              {errors.swift_code}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 my-1">
                        <div className="form-group">
                          <label className="form-label">IBAN Code </label>
                          <input
                            type="text"
                            className="form-control"
                            name="iban_code"
                            value={bankDetails.iban_code}
                            placeholder="Enter IBAN Code"
                            onChange={handleInputChange}
                          />
                          {errors.iban_code && (
                            <span className="text-danger">
                              {errors.iban_code}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      name=""
                      value={payAccId}
                      placeholder={
                        payMode == "UPI"
                          ? "Enter Your UPI ID"
                          : "Enter Your Payment ID"
                      }
                      onChange={(e) => {
                        setPayAccId(e.target.value.trim());
                      }}
                    />
                  )}
                  {/* added by arvind */}
                </div>
                {(payMode === "upi" || payMode === "UPI") && (
                  <div className="file-upload-card">
                    <div className="file-preview-container">
                      {file ? (
                        <div className="file-preview">
                          <button
                            className="file-remove-btn"
                            onClick={handleFileRemove}
                            aria-label="Remove file"
                          >
                            &times;
                          </button>
                          {file.type.startsWith("image/") && (
                            <img
                              src={fileURL}
                              alt="File Preview"
                              className="file-image"
                            />
                          )}
                        </div>
                      ) : imageUrl ? (
                        <div className="file-preview">
                          <button
                            className="file-remove-btn"
                            onClick={handleFileRemove}
                            aria-label="Remove file"
                          >
                            &times;
                          </button>
                          <img
                            src={imageUrl}
                            alt="File Preview"
                            className="file-image"
                          />
                        </div>
                      ) : null}
                    </div>
                    {/* </div> */}
                    <label className="btn btn-primary mt-2 mb-0 upload-button">
                      <input
                        type="file"
                        className="sm-input"
                        onChange={handleFileChange}
                        accept="application/pdf, image/*"
                        style={{ display: "none" }} // Hide the default file input
                      />
                      {file
                        ? "Replace QR"
                        : imageUrl
                        ? "Replace QR"
                        : "Upload QR"}
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>

        <Card className="card-bordered paycard">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="between-center  flex-md-nowrap g-3">
                <div className="nk-block-text">
                  <h6>Withdrawal Limit</h6>
                  <p>
                    Set your withdrawal limit here - (Minimum <b>${lim} </b>{" "}
                    required)
                  </p>
                  <Nouislider
                    className="form-range-slider"
                    accessibility
                    connect={[true, false]}
                    start={wamt}
                    step={10}
                    range={{
                      min: lim,
                      max: 10000,
                    }}
                    onChange={(e) => {
                      setWamt(parseInt(e[0]));
                      // setLim(parseInt(e[0]))
                    }}
                  />
                </div>
                <div className="nk-block-actions">
                  <h4>${wamt ? wamt : 0}</h4>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <div className="mt-3 pt-2 payment-button">
          <Button color="primary" onClick={savePayData}>
            Update Payment Method
          </Button>
        </div>
      </Block>
      {/* <ToastContainer /> */}
    </React.Fragment>
  );
};
export default UserPayment;
